html { 
    /*background: url("../images/bg.jpeg") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
}

body{
    background: #000000;
    color: #fff;
    font-family:'roboto-regular','Open Sans',Arial,Helvetica,sans-serif;
}

html,body{
    height: 100%
}

.wrap{
    min-height: 100%;
    margin-top: 30px;
    background: #0c0c0c;
}

.carousel-content{
    .thumb-img {
        width: 100%;
        height: 480px;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .carousel-caption{
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        color: #fff;
        background: rgba(0, 0, 0, 0.88);
        width: 100%;
        left: 0;
        text-align: left;

        a{
            color: #fff;
            h3{
                white-space: nowrap; 
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    img{
        width: 100%;
    }
    .featured-news-content{
        background: #0a0a0a;
        padding: 10px 15px;
        cursor: pointer;
        display: flex;
        /* flex-flow: row nowrap; */
        justify-content: space-between;
        align-items: center;
        /* justify-content: center; */

        .thumb-img {
            width: 125px;
            height: 100px;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
        }
        
        .title {
            width: 145px;
            padding: 0px 5px;
        }
        
        .rating {
            border: 3px solid #2a2a2a;
            padding: 10px 15px;
            border-radius: 5px;
            background-color: #101010;
            min-width: 68px;
            text-align: center;
        }
    }

    .active{
        background-color: $brand-red;
    }
}

.provider-menu{
    padding: 15px 0 0;
    border-bottom: 2px solid $brand-gold;

    .menu{
        display: inline-block;
        text-transform: uppercase;
        font-weight: 700;
        padding: 5px 10px;
        cursor: pointer;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: -7px;
    }

    .active{
        background-color: $brand-gold;
    }
}

.provider-content{
    padding: 20px 0;

    .content{
        &:not(:first-of-type){
            display: none;
        }
        
        /*&:not(:last-of-type){
            .row{
                margin-bottom: 5px;
            }
        }*/

        .row{
            padding-right: 15px!important;
            padding-left: 15px!important;
            margin-bottom: 10px;
        }

        .thumb-img{
            height: 125px;  
            width: 100%;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .news-content{
            .post-cat-date{
                text-transform: uppercase;
                font-size: 12px;
            }

            a{
                color: #fff;

                &:hover{
                    color: $brand-red;
                }
                h5{
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    font-weight: 700;
                    font-size: 23px;
                    margin-bottom: 0;
                }
            }

            p{
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical; 
                //min-height: 44px;
                font-size: 14px;
                margin-top: 10px;
            }
        }

        .rating{
            display: flex;

            div{
                margin: auto;
                text-align: center;
                border: 3px solid #2a2a2a;
                padding: 10px 15px;
                border-radius: 5px;
                background-color: #101010;
                min-width: 68px;
                text-align: center;
            }
        }
    }
}

.popular-games-content{
    padding: 20px 0;

    .popular-games-title{
        margin-bottom: 0;
        border-left: 3px solid $brand-gold;
        padding: 0px 0px 5px 10px;

        h4{
            margin-bottom: 0;
        }
    }

    .popular-games-contents{
        background: #0a0a0a;
        margin-top: 10px;

        .row{
            &:not(:last-of-type){
                border-bottom: 1px dotted $brand-yellow;
            }
            margin: 0;
        }

        .content{
            .number{
                text-align: center;
                background-color: #101010;
                padding: 10px 0;
                margin-bottom: 0;
            }
            .title{
                margin-bottom: 0;
                padding-left: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 10px 15px;
                
                a{
                    color: #fff;

                    &:hover{
                        color: $brand-red;
                    }
                }
            }
            .rating{
                padding: 10px;
                text-align: center;
            }
        }
    }
}

.ads-sidebar-img{
    img{
        width: 100%;
    }
}

.game-provider{
    padding: 20px 50px;

    .page-header{
        text-align: center;
        padding-bottom: 30px;
        border-bottom: 1px dotted $brand-gold;;

        img{
            width: 350px;
            height: 135px;
        }
    }

    .page-content{
        padding: 20px 0;
        border-bottom: 1px dotted $brand-gold;;
    }

    .other-contents{
        padding: 30px 0;

        .slot-games{
            .block-title{
                text-align: left;
                border-bottom: 1px solid $brand-gold;
        
                i{
                    margin-right: 15px;
                    font-size: 27px;
                    color: $brand-gold;
                }
                h3{
                    color: #fff;
                    display: inline-block;
                }
            }
        
            .block-content{
                padding: 30px 0;
        
                .article{
                    
                    &:not(:first-of-type){
                        margin-top: 30px; 
                    }
        
                    .thumb-img{
                        height: 170px;
                        width: 100%;
                        background-position: 50%;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
        
                    .article-content{
                        h4{
                            white-space: nowrap; 
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: $brand-yellow;
                            margin-bottom: 0;
                        }
        
                        p{
                            margin-top: 10px;
                            font-size: 14px;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            margin-bottom: 25px;
                        }
        
                        a{
                            background-color: #d1000b;
                            font-weight: bold;
                            padding: 5px 20px;
                            color: $brand-yellow;
                
                            &:hover{
                                color: $brand-gold;
                            }
                        }
                    }
                }
            }
        }

        .popular-games-content{
            padding: 0!important;
        }
    }
}

.page-content{
    padding: 20px 50px;
}

.blog-post-items{
    margin-bottom: 20px;
    .thumb-img{
        height: 250px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .post-content{
        padding: 5px 20px;

        .post-title{
            color: $brand-yellow;
            margin-bottom: 0;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .post-cat-date{
            font-size: 14px;
        }

        .post-content{
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical; 
            padding: 0!important;
            margin-top: 10px;
            //min-height: 44px;
        }

        .readMore-btn{
            background-color: #d1000b;
            font-weight: bold;
            padding: 3px 10px;
            font-size: 13px;
            color: $brand-yellow;

            &:hover{
                color: $brand-gold;
            }
        }
    }
}

.single-post-container{
    border-bottom: 1px dotted $brand-gold;

	.post-cat-date{
		font-size: 14px;
	}

	.post-title{
		font-size: 38px;
        font-weight: bold;
        color: $brand-yellow;
	}

	img{
		width: 100%;
		padding: 10px 0;
		height: 100%;
	}

	.post-content{
		padding: 10px 0;
	}
}

.sidebar{    

    .top-10-games{
        margin: 4px 0;
        border-bottom: 1px dotted $brand-gold; 

        .block-title{
            text-align: left;
            border-bottom: 1px solid $brand-gold;

            i{
                margin-right: 15px;
                font-size: 22px;
                color: $brand-gold;
            }
            h4{
                color: #fff;
                display: inline-block;
            }
        }

        .block-content{
            ul{
                padding: 10px 0 20px 10px;

                li{
                    list-style: none;
                    display: table;

                    &:not(:first-of-type){
                        margin-top: 20px; 
                    }

                    h5{
                        white-space: nowrap; 
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline-block;
                        //display: table-cell; 
                        vertical-align: middle;
                        width: 140px;
                        padding-right: 10px;
                    }

                    .rating{
                        display: inline-block;
                        margin-right: 20px;
                        display: table-cell;
                        vertical-align: middle;
                        padding-right: 15px;
                        font-size: 14px;
                    }
                    
                    a{
                        background-color: #d1000b;
                        font-weight: bold;
                        padding: 3px 10px;
                        font-size: 13px;
                        color: $brand-yellow;
                        display: table-cell;
                        vertical-align: middle;

                        &:hover{
                            color: $brand-gold;
                        }
                    }
                } 
            }

            .checked{
                color: $brand-yellow;
            }
        }
    }
}