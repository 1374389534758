.banner {
    background: $brand-black;
	padding-top: 10px;
	border-bottom: 4px solid $brand-yellow;

	.border-gold{
		border-bottom: 1px dotted $brand-yellow;
	}

	.brand{
		font-family:'gang-of-three','Open Sans',Arial,Helvetica,sans-serif;
		color: $brand-red;
		font-size: 50px;

		label{
			color: #fdfdfd;
			cursor: pointer;
		}
	}
	
	.whablog-menus{
		color: #fff;
		height: 40px;
			
		nav{
			background: $brand-black;
	
			ul {
				padding: 0;
				margin: 0;
				list-style: none;
				position: relative;
				
				li {
					display:inline-block;
					
					&:hover > .parent-ul {
						display:inherit;
					} 
				}
				
				a {
					display:block;
					padding:0 15px;	
					color:#FFF;
					font-size:16px;
					line-height: 40px;
					text-decoration:none;
					text-transform: uppercase;
					
					&:hover{
						color: $brand-gold;
					}
				}
			}
		}
		
		@media screen and (max-width: 991px) {
			display: none;
		}
	
	}
}